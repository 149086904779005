/* antonio-regular - latin */
@font-face {
  font-family: 'Antonio';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/antonio-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/antonio-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/antonio-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/antonio-v9-latin-regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/antonio-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/antonio-v9-latin-regular.svg#Antonio') format('svg'); /* Legacy iOS */
}
/* antonio-700 - latin */
@font-face {
  font-family: 'Antonio';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/antonio-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/antonio-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/antonio-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/antonio-v9-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/antonio-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/antonio-v9-latin-700.svg#Antonio') format('svg'); /* Legacy iOS */
}
