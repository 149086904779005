h2,
.h2 {
  @include fluid-type(20px, xd-to-px(70));
  line-height: 1.35;
}
h3,
.h3 {
  @include fluid-type(20px, xd-to-px(30));
  line-height: 1.35;
}
.text {
  p {
    letter-spacing: 1.7px;
    line-height: 30px;
    @apply mb-3 font-light;
  }
}
.quote {
  p {
    letter-spacing: 1.95px;
    @include fluid-type(20px, 30px, 'line-height');
    @include fluid-type(14px, 22px);
    @apply mb-6 font-light;
  }
  small {
    letter-spacing: 1px;
    @apply font-light;
  }
}
