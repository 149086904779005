@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function pc($px, $grid: 1600) {
  @return floor($px / $grid * 1000) / 10 * 1%;
}

@function xd-to-px($value) {
  // XD 120 => 96px, factor 0.8
  @return $value * 0.8px;
}

@mixin fluid-type($min-font-size, $max-font-size, $property: 'font-size') {
  $min-vw: 320px;
  $max-vw: 1280px; //theme('screens.xl')

  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$property}: $min-font-size;
      @media screen and (min-width: $min-vw) {
        #{$property}: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        #{$property}: $max-font-size;
      }
    }
  }
}
